import React, { Fragment } from "react";
import Translate from "react-translate-component";

import { Container } from "../../../components/Container/Container";
import { SelectCard } from "../../../components/SelectCard/SelectCard";
import {
  HeadingLg,
  HeadingLgWithSideLines,
} from "../../../components/Heading/Heading";
import { Grid, GridItem } from "../../../components/Grid/Grid";
import { FormLabel } from "../../../components/Form/FormLabel";
import SelectContainer from "../../../components/Form/SelectContainer";

export default ({
  onSelect,
  selectedCard,
  activeSelectDropdown,
  onDropdownValueSelect,
  selectDropdownData,
  positionName,
  fillAssets,
  myRef,
  isVega,
  isImperialPlus,
}) => (
  <Container lg style={{ display: "flex" }}>
    <Container md style={{ paddingTop: "10rem" }} ref={myRef}>
      <HeadingLgWithSideLines>
        <HeadingLg>
          <Translate content="filling.TITLE" />
        </HeadingLg>
      </HeadingLgWithSideLines>

      <Grid columns3>
        {isVega || isImperialPlus ? null : (
          <>
            <GridItem>
              {selectedCard === "filling.type.classic" ? (
                <Fragment>
                  <SelectCard
                    lg
                    active
                    onSelect={onSelect}
                    type="filling.type.classic"
                    image="images/roof-fills/roof-fill--transparent-polycarbonate.jpg"
                    lightboxId="filling.type.classic"
                    lightboxImages={fillAssets.classic.galleryImgs}
                  >
                    <Translate content="filling.POLYCARBONATE" />
                  </SelectCard>
                </Fragment>
              ) : (
                <SelectCard
                  lg
                  onSelect={onSelect}
                  type="filling.type.classic"
                  image="images/roof-fills/roof-fill--transparent-polycarbonate.jpg"
                  lightboxId="filling.type.classic"
                  lightboxImages={fillAssets.classic.galleryImgs}
                >
                  <Translate content="filling.POLYCARBONATE" />
                </SelectCard>
              )}
            </GridItem>
            <GridItem>
              {selectedCard === "filling.type.smoke_polycarbonate" ? (
                <Fragment>
                  <SelectCard
                    lg
                    active
                    onSelect={onSelect}
                    type="filling.type.smoke_polycarbonate"
                    image="images/roof-fills/roof-fill--smoked-polycarbonate.jpg"
                    lightboxId="filling.type.smoke_polycarbonate"
                    lightboxImages={fillAssets.smokePolycarbonate.galleryImgs}
                  >
                    <Translate content="filling.SMOKED_POLYCARBONATE" />
                  </SelectCard>
                </Fragment>
              ) : (
                <SelectCard
                  lg
                  onSelect={onSelect}
                  type="filling.type.smoke_polycarbonate"
                  image="images/roof-fills/roof-fill--smoked-polycarbonate.jpg"
                  lightboxId="filling.type.smoke_polycarbonate"
                  lightboxImages={fillAssets.smokePolycarbonate.galleryImgs}
                >
                  <Translate content="filling.SMOKED_POLYCARBONATE" />
                </SelectCard>
              )}
            </GridItem>
          </>
        )}

        <GridItem>
          {selectedCard === "filling.type.compact_polycarbonate" ? (
            <Fragment>
              <SelectCard
                lg
                active
                onSelect={onSelect}
                type="filling.type.compact_polycarbonate"
                image="images/roof-fills/roof-fill--clear-boards.jpg"
                lightboxId="filling.type.compact_polycarbonate"
                lightboxImages={fillAssets.compactPolycarbonate.galleryImgs}
              >
                <Translate content="filling.CLEAR_POLYCARBONATE" />
              </SelectCard>
              <FormLabel>
                <Translate content="filling.SPECIFY" />
              </FormLabel>
              {activeSelectDropdown === selectDropdownData[2].id ? (
                <SelectContainer
                  selectDropdownData={selectDropdownData[2]}
                  open
                  onDropdownValueSelect={onDropdownValueSelect}
                  DropdownValueName={positionName}
                />
              ) : (
                <SelectContainer
                  selectDropdownData={selectDropdownData[2]}
                  onDropdownValueSelect={onDropdownValueSelect}
                  DropdownValueName={positionName}
                />
              )}
            </Fragment>
          ) : (
            <SelectCard
              lg
              onSelect={onSelect}
              type="filling.type.compact_polycarbonate"
              image="images/roof-fills/roof-fill--clear-boards.jpg"
              lightboxId="filling.type.compact_polycarbonate"
              lightboxImages={fillAssets.compactPolycarbonate.galleryImgs}
            >
              <Translate content="filling.CLEAR_POLYCARBONATE" />
            </SelectCard>
          )}
        </GridItem>
      </Grid>
    </Container>
  </Container>
);
